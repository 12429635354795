/*
  This content is mostly be copied over from MakePayment.tsx and still needs a cleanup/refactor itself
*/
import { ApolloQueryResult } from '@apollo/client';
import { useCallback, useState } from 'react';

import { CompanyFragment, PaymentMethod, PortalSelfQuery } from '@willow/graphql-iso/src/portal';
import { stripNullFromNullOrUndefined } from '@willow/shared-iso';
import { getUnfulfilledPaymentsDue, isGqlStatementBreakdown, NamedMemo } from '@willow/shared-web';

import { PortalSelectedLoan } from '../../../App';
import { getTotalAmountDue } from '../../../pages/make-payment/utils';
import { getFeesDueSorted } from '../../../utils/fees';
import { InterestReservePayment } from '../../interest-reserve-payment/InterestReservePayment';
import { PaymentForm } from '../../payment-form/PaymentForm';
import { PaymentDetails } from './payment-details/PaymentDetails';
import { PaymentHeader } from './payment-header/PaymentHeader';
import { PaymentProcessing } from './PaymentProcessing';
import { PreviousPayment } from './previous-payment/PreviousPayment';

interface Props {
  company: CompanyFragment;
  loan: PortalSelectedLoan;
  refetch?: () => Promise<ApolloQueryResult<PortalSelfQuery>>; // refetch after successful submission
}

export const PaymentsAccepted = NamedMemo<Props>('PaymentsAccepted', ({ company, loan, refetch }) => {
  const {
    id: loanId,
    currentSnapshot: {
      loanStatus,
      paymentDueStatus,
      paymentDueSubStatus,
      totalAmountRemainingFromBorrower,
      escrowShortageAnalysisId,
      shortageAmount,
      chargeAmountDue,
      paymentsDue,
      paymentToPaymentDue,
      payments,
      charges,
      payoffs,
      nextStatementDate,
      transferData,
      autopaySettings,
      stopSettings,
      currentPaymentConfig: { delinquentInterestRate },
      currentBillSettingsType,
    },
    configuration: { hasInterestReserve },
  } = loan;

  const borrowerHasAmountRemaining = totalAmountRemainingFromBorrower > 0;
  const hasPaymentDue = paymentDueStatus === 'due' || borrowerHasAmountRemaining;
  const hasInterestReserveDue = paymentDueStatus !== 'submitted' && !borrowerHasAmountRemaining && hasInterestReserve;

  /*
      Future enhancement: just copying the below over from makepayment.tsx
      this stuff should be handled by paymentform
  */
  const [displayAdditionalPayment, setDisplayAdditionalPayment] = useState(false); // Allow additional payments to be made within certain statuses
  const unfulfilledCharges = getFeesDueSorted(charges);

  // Determine if there are processing payments
  let processingPayments: PortalSelectedLoan['currentSnapshot']['payments'] = [];
  let isOutstanding: boolean = false;
  if (
    (['due', 'submitted'].includes(paymentDueStatus) || borrowerHasAmountRemaining) &&
    paymentToPaymentDue.length > 0
  ) {
    // Check if any payments have been made already associated with any of the paymentsDue items
    const unfulfilledPayments = getUnfulfilledPaymentsDue(paymentsDue);
    const paymentDueIds = unfulfilledPayments?.map((unfulfilledPayement) => unfulfilledPayement.id);
    const associatedPaymentIds = paymentToPaymentDue
      .filter((ppd) => {
        return isGqlStatementBreakdown(ppd) && paymentDueIds?.includes(ppd.paymentDueId);
      })
      .map((item) => item.paymentId);

    if (payments) {
      // Find all associated payments for this payment due
      const newAssociatedPayments = associatedPaymentIds?.length
        ? payments.filter((paymentItem) => {
            return associatedPaymentIds.includes(paymentItem.id);
          })
        : [];

      // Determine if there is an outstanding balance status
      // Outstanding if payment is still due + there are already payments associated with it
      isOutstanding = paymentDueStatus === 'due' && newAssociatedPayments.length > 0;

      // Pick out processing payments for the current paymentDue
      // if "due" status: we are only interested in unfulfilled payments (the totalAmountRemainingFromBorrower > 0 if there are outstanding charges)
      // if "submitted" status, all payments will already be marked fulfilled, so look through all payments for processing status
      const statusProcessingPayments = paymentDueStatus === 'due' ? newAssociatedPayments : payments;
      processingPayments = statusProcessingPayments.filter((paymentItem) => {
        return paymentItem.status === 'PENDING' && paymentItem.method !== PaymentMethod.Reserve;
      });
    }
  }

  const totalAmountDue = getTotalAmountDue(loan);
  const hasActivePayoffStatement = payoffs.some((p) => ['status', 'pending'].includes(p.status));
  const exactPaymentOnly =
    stopSettings.portalPayments === 'exact' || Boolean(loan.company.adminSettings.exactPaymentsOnly);

  const handlePaymentSubmission = useCallback(() => {
    // After a payment has been successfully submitted, hide the form until the user requests it again
    setDisplayAdditionalPayment(false);
  }, []);

  return (
    <>
      <PaymentHeader loan={loan} />

      {hasPaymentDue && (
        <>
          <PaymentForm
            loanId={loanId}
            loanStatus={loanStatus}
            company={company}
            totalAmountDue={totalAmountDue}
            shortageAmount={shortageAmount}
            escrowShortageAnalysisId={escrowShortageAnalysisId}
            chargesAmountDue={chargeAmountDue}
            paymentsDue={paymentsDue}
            processingPayments={processingPayments}
            chargesDue={unfulfilledCharges}
            subStatus={paymentDueSubStatus}
            hasOutstandingBalance={isOutstanding}
            nextStatementDate={nextStatementDate}
            autopaySettings={autopaySettings}
            hasInterestReserve={loan.configuration.hasInterestReserve}
            hasPayoffStatement={hasActivePayoffStatement}
            exactPaymentOnly={exactPaymentOnly}
            refetch={refetch}
            delinquentInterestRate={delinquentInterestRate ?? 0}
            currentBillSettingsType={currentBillSettingsType}
          />
          {processingPayments?.length > 0 && (
            <PaymentDetails payments={processingPayments} status={paymentDueStatus} subStatus={paymentDueSubStatus} />
          )}
        </>
      )}

      {paymentDueStatus === 'submitted' && !borrowerHasAmountRemaining && (
        <PaymentProcessing
          processingPayments={processingPayments}
          status={paymentDueStatus}
          subStatus={paymentDueSubStatus}
          autopaySettings={autopaySettings ?? undefined}
          onAdditionalPayment={() => {
            setDisplayAdditionalPayment(true);
          }}
        />
      )}

      {paymentDueStatus === 'notDue' && !borrowerHasAmountRemaining && (
        <PreviousPayment
          paymentsDue={paymentsDue}
          paymentToPaymentDue={paymentToPaymentDue || []}
          paymentsMade={payments}
          onAdditionalPayment={() => {
            setDisplayAdditionalPayment(true);
          }}
          transferData={stripNullFromNullOrUndefined(transferData)}
        />
      )}

      {hasInterestReserveDue && (
        <InterestReservePayment
          onAdditionalPayment={() => {
            setDisplayAdditionalPayment(true);
          }}
        />
      )}
      {displayAdditionalPayment && (
        <PaymentForm
          loanId={loanId}
          loanStatus={loanStatus}
          company={company}
          totalAmountDue={totalAmountDue}
          shortageAmount={shortageAmount}
          escrowShortageAnalysisId={escrowShortageAnalysisId}
          chargesAmountDue={chargeAmountDue}
          paymentsDue={paymentsDue || []}
          processingPayments={processingPayments}
          chargesDue={unfulfilledCharges}
          subStatus={paymentDueSubStatus}
          isAdditionalPayment
          nextStatementDate={nextStatementDate}
          autopaySettings={autopaySettings}
          hasInterestReserve={hasInterestReserve}
          hasPayoffStatement={hasActivePayoffStatement}
          exactPaymentOnly={exactPaymentOnly}
          onPaymentSubmission={handlePaymentSubmission}
          refetch={refetch}
          delinquentInterestRate={delinquentInterestRate ?? 0}
          currentBillSettingsType={currentBillSettingsType}
        />
      )}
    </>
  );
});
