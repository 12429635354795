/*
    Handle style customizations based on lender settings
*/
import { useMemo } from 'react';

import { CompanyFragment } from '@willow/graphql-iso/src/portal';

import { hexToRgbA } from './utils';

export const useStyleCustomization = (company: CompanyFragment | undefined) => {
  const primaryColor = useMemo<string | undefined>(() => company?.portalSettings?.primaryColor || undefined, [company]);
  const secondaryColor = useMemo<string | undefined>(
    () => (primaryColor ? hexToRgbA(primaryColor) : undefined),
    [primaryColor],
  );

  return { primaryColor: primaryColor || 'var(--color-blue1)', secondaryColor: secondaryColor || 'var(--color-blue0)' };
};
