/*
    Both the app and portal display statements + documents in a single list (overload the functions to handle both cases)
    This utility combines -> sorts -> outputs the display data necessary for the components
*/

import { DateTime } from 'luxon';

import {
  AppPaymentDueFragment,
  BorrowerDocumentAccess as AppBorrowerAccess,
  DocumentType,
  LoanDocumentDeliveryStatus,
} from '@willow/graphql-iso/src/app';
import { PortalPaymentDueFragment } from '@willow/graphql-iso/src/portal';
import { DocumentId, PaymentDueId } from '@willow/types-iso';

import { getCollectablePaymentsDue } from './currentPaymentDue';
import { dateFormat } from './dateFormat';

export const RevisedCallout = 'Replaced by revised copy';

/* DEFINE TYPES FOR TABLE DISPLAY FORMATTING */
type BaseItem = {
  notificationType: 'document' | 'email' | 'manuallyUploaded';
  title: string;
  subtitle?: string;
  callouts: string[];
  sortByDate: Date;
  version: number;
  date: Date | string; // date pre-sorted -> string formatted post-sorting
  borrowerAccess?: AppBorrowerAccess;
};

export type StatementItem = BaseItem & {
  id: PaymentDueId;
  documentType: 'Statement';
};

export type DocumentItem = BaseItem & {
  id: DocumentId;
  documentType: 'Document';
  documentSpecificationType: DocumentType;
  delivery?: { status: LoanDocumentDeliveryStatus; date?: string };
};

export type DocumentDisplayItem = StatementItem | DocumentItem;

/* HELPER FUNCTIONS FOR FORMATTING TABLE ITEMS (APP + PORTAL OVERLOADED */
export function getStatementItems(paymentsDue: AppPaymentDueFragment[]): DocumentDisplayItem[];
export function getStatementItems(paymentsDue: PortalPaymentDueFragment[]): DocumentDisplayItem[];
export function getStatementItems(
  paymentsDue: AppPaymentDueFragment[] | PortalPaymentDueFragment[],
): DocumentDisplayItem[] {
  // Filter out statements that we don't expect to collect on
  const collectablePayments = getCollectablePaymentsDue(paymentsDue);

  const mappedStatements = collectablePayments.flatMap((paymentDue) => {
    const { id, statementDate, paymentDate, status } = paymentDue;
    const statements = paymentDue.statements.map((s, index) => {
      return { ...s, isOutdated: index < paymentDue.statements.length - 1 };
    });

    // Flatten statements (there may be multiple per paymentDue if there are revisions)
    return statements?.flat().map((statement) => {
      const callouts: string[] = [];
      const { createdAt, version, isOutdated } = statement;
      isOutdated && callouts.push(RevisedCallout);
      status.isDeferred && callouts.push('Deferred');
      const statementSentDate = getStatementSentDate(createdAt, statementDate);

      return {
        id,
        notificationType: 'document' as const,
        documentType: 'Statement' as const,
        title: `Monthly Statement (${dateFormat(paymentDate, 'LLL yyyy')})`,
        callouts,
        sortByDate: new Date(statementDate),
        date: statementSentDate,
        version,
        borrowerAccess: 'borrowerAccess' in statement ? statement.borrowerAccess : undefined, // Shared component, but portal does not have borrowerAccess
      };
    });
  });

  return mappedStatements;
}

const getStatementSentDate = (createdAt: any, statementDate: any) => {
  createdAt = new Date(createdAt);
  statementDate = new Date(statementDate);

  return DateTime.max(DateTime.fromJSDate(createdAt), DateTime.fromJSDate(statementDate)).toJSDate();
};

export const sortDocumentsByDate = (documents: DocumentDisplayItem[]): DocumentDisplayItem[] => {
  // Sorted by createdAt date (newest documents display first)
  const sortedByDate = documents.sort((a, b) => {
    const dateA = new Date(a.sortByDate).getTime();
    const dateB = new Date(b.sortByDate).getTime();

    if (dateA === dateB) {
      return b.version > a.version ? 1 : -1;
    }

    // Return most recent date first
    return dateB > dateA ? 1 : -1;
  });

  return sortedByDate;
};
