/*
    This form is used by borrowers to request a payoff statement.
    This version of the form simply creates a task for the lender, and it is up to them to populate the statement with the requested information
*/

import { useMutation } from '@apollo/client';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { DateTime } from 'luxon';
import { useContext, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { RequestPayoffStatementDocument } from '@willow/graphql-iso/src/portal';
import { Button, ControlledSingleDatePicker, MultiSelect, NamedMemo, z } from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';
import { BorrowerId, zodBrandedUuid } from '@willow/types-iso';

import { PortalSelectedLoan } from '../../App';
import { Alert } from '../alert/Alert';
import { UserContext } from '../context/AppContexts';
import { WillowModal } from '../modal/Modal';
import { ManageLoanHeader } from './Header';

interface Props {
  loan: PortalSelectedLoan;
}

const PAYOFF_REQUEST_FORM_SHAPE = z.object({
  payoffDate: z.date(),
  additionalReceipients: zodBrandedUuid<BorrowerId>().array(),
  message: z.string().optional(),
});
type PayoffRequestFormShape = z.infer<typeof PAYOFF_REQUEST_FORM_SHAPE>;

export const PayoffRequestForm = NamedMemo<Props>('PayoffRequestForm', ({ loan }) => {
  const [requestPayoffStatement] = useMutation(RequestPayoffStatementDocument);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const user = useContext(UserContext);

  const {
    formState: { errors, isSubmitting },
    register,
    control,
    handleSubmit,
    reset,
  } = useForm<PayoffRequestFormShape>({
    resolver: zodResolver(PAYOFF_REQUEST_FORM_SHAPE),
    defaultValues: {
      additionalReceipients: [],
    },
  });

  // Filter out current borrower from list of additional recipient options
  const borrowers = [loan.primaryBorrower, ...loan.additionalBorrowers].filter((b) => !(b.id === user?.borrowerId));
  const recipientOptions = useMemo(
    () => [
      ...borrowers.map((b) => {
        return {
          value: b.id,
          label: (
            <>
              {b.firstName} {b.lastName}
            </>
          ),
        };
      }),
    ],
    [borrowers],
  );

  const onSubmit = handleSubmit(async (data) => {
    try {
      await requestPayoffStatement({
        variables: {
          loanId: loan.id,
          payoffDate: data.payoffDate,
          additionalBorrowers: data.additionalReceipients,
          message: data.message,
        },
      });
      setShowSuccessModal(true);
      reset();
    } catch (err) {
      toast.error('Something went wrong submitting the payoff request form, please try again.');
    }
  });

  return (
    <>
      {loan.currentSnapshot.openPayoffStatus && !isSubmitting && !showSuccessModal && (
        <div className="mb-4">
          <Alert
            title=""
            subtitle={
              <>
                {loan.currentSnapshot.openPayoffStatus === 'requested' && (
                  <>
                    <b>Payoff Requested:</b> Requesting a new payoff will cancel and replace currently active request
                  </>
                )}
                {loan.currentSnapshot.openPayoffStatus === 'active' && (
                  <>
                    <b>Active Payoff:</b> Requesting a new payoff will cancel and replace currently active statement
                  </>
                )}
              </>
            }
            level="WARNING"
            variant="BOLD"
          />
        </div>
      )}

      <ManageLoanHeader
        title="Request a Payoff Statement"
        subtitle="Fill out the form below to request a payoff statement be emailed to you. "
      />

      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-4">
          <Form.Label className="u-fs-2">
            <div className="u-bold">Select payoff date</div>
            <div className="u-color-bark3">This is the date you expect to pay your loan in full</div>
          </Form.Label>

          <ControlledSingleDatePicker
            fieldName="payoffDate"
            control={control}
            props={{
              minDate: DateTime.now().startOf('day').toJSDate(), // don't allow backdating
              maxDate: DateTime.now().startOf('day').plus({ days: 30 }).toJSDate(), // only allow up to 30 days for payoff expiration
            }}
          />
        </Form.Group>

        {borrowers.length > 0 && (
          <Form.Group className="mb-4">
            <Form.Label className="u-fs-2">
              <div className="u-bold">Additional payoff quote recipient (optional)</div>
              <div className="u-color-bark3">Select authorized contact(s) to also receive a payoff quote</div>
            </Form.Label>
            <Controller
              name="additionalReceipients"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  placeholder="Select recipient(s)"
                  options={recipientOptions}
                  value={recipientOptions.filter((r) => value.includes(r.value))}
                  onChange={(notificiationRecipients) => {
                    onChange({
                      target: {
                        name: `recipients`,
                        value: notificiationRecipients
                          ? notificiationRecipients.map((recipient) => recipient.value)
                          : [],
                      },
                    });
                  }}
                />
              )}
            />
          </Form.Group>
        )}

        <Form.Group>
          <Form.Label className="u-fs-2 u-bold">Add additional notes to Lender (optional)</Form.Label>
          <Form.Control
            as="textarea"
            rows={7}
            className="w-100"
            {...register('message')}
            placeholder="Add your note here"
          />

          <ErrorMessage
            errors={errors}
            name="message"
            render={({ message }) => <div className="mt-1 u-color-red1 w-100">{message}</div>}
          />
        </Form.Group>

        <Button className="mt-3 align-self-right" variant="primary" size="md" onClick={onSubmit} loading={isSubmitting}>
          Submit Request
        </Button>
      </Form>

      <WillowModal showModal={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
        {/* <h2 className="u-fs-4 u-bold mb-3">Payoff Statement Requested</h2>
        <div>
          {loan.company.name} is generating your payoff statement and you will be notified via email when it is ready
          for download from your Documents section.
        </div> */}
        <Alert title="Your payoff statement request has been submitted" level="INFO" />
        <div className="mt-3">
          {loan.company.name} is generating your payoff statement and you will be notified via email when it is ready
          for download from your Documents section.
        </div>
        <div className="mt-4 ms-auto">
          <Button
            variant="primary"
            size="sm"
            className="hover-override hover:u-bg-secondary"
            onClick={() => setShowSuccessModal(false)}
          >
            Close
          </Button>
        </div>
      </WillowModal>
    </>
  );
});
