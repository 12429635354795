import { NavLink } from 'react-router-dom';

import { phoneNumberFormat } from '@willow/shared-iso';
import { NamedMemo, PORTAL_LOAN_PATHS } from '@willow/shared-web';

import { EmptyPortalUser, PortalUser } from '../context/AppContexts';

interface Props {
  losId: string;
  companyId: string;
  user: PortalUser | EmptyPortalUser;
}

export const AccountInformation = NamedMemo<Props>('AccountInformation', ({ user, losId, companyId }) => {
  return (
    <>
      <div className="mb-2 u-border-1 u-border-color-bark1 u-border-r-3">
        <div className="px-4 py-3 d-flex align-items-center justify-content-between u-border-b-1 u-border-color-bark1">
          <h3 className="fw-bold">Full Name</h3>
          <p className="u-color-bark3">
            {user.firstName} {user.lastName}
          </p>
        </div>

        <div className="px-4 py-3 d-flex align-items-center justify-content-between u-border-b-1 u-border-color-bark1">
          <h3 className="fw-bold">Email Address</h3>
          <p className="u-color-bark3">{user.email}</p>
        </div>

        <div className="px-4 py-3 d-flex align-items-center justify-content-between">
          <h3 className="fw-bold">Phone</h3>
          <p className="u-color-bark3">{user.phone && phoneNumberFormat(user.phone)}</p>
        </div>
      </div>

      <div className="mt-5 mx-auto text-center" style={{ maxWidth: '300px' }}>
        If your account information has changed
        <br />
        <NavLink
          to={PORTAL_LOAN_PATHS.manageLoan.contactUs({ losId, companyId })}
          exact={false}
          className="u-color-blue1 u-bold"
        >
          contact us for assistance.
        </NavLink>
      </div>
    </>
  );
});
