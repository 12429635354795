import { reverse } from 'lodash';

import { PortalChargeFragment, PortalPaymentDueFragment } from '@willow/graphql-iso/src/portal';
import { subtract } from '@willow/shared-iso';
import { getUnfulfilledPaymentsDue, paymentAmountFormat, paymentDateFormat } from '@willow/shared-web';

export type PaymentFormAmountDueDisplay = { desktop: string; tablet: string };

export type PortalDue = PortalPaymentDueFragment | PortalChargeFragment;

export const formatPortalDueItem = (
  dueItem: PortalDue,
  isShortagePaidUpfront: boolean,
): PaymentFormAmountDueDisplay => {
  switch (dueItem.__typename) {
    case 'PaymentDue':
      return formatPaymentDueItem(dueItem, isShortagePaidUpfront);
    case 'Advance':
    case 'Fee':
      return formatChargeDueItem(dueItem);
    default:
      // assertNever does not like dueItem or dueItem.__typename
      return { desktop: '', tablet: '' };
  }
};

const formatPaymentDueItem = (
  paymentDue: PortalPaymentDueFragment,
  isShortagePaidUpfront: boolean,
): PaymentFormAmountDueDisplay => {
  const { paymentDate, status, escrowShortage } = paymentDue;

  // If this paymentDue has a shortage, we do this adjustment
  // amountRemainingFromBorrower includes the 1/12 escrowShortage spread by default, so we subtract it off
  // This will match the numbers from Option 2 on the Escrow Analysis PDF.
  const amount = isShortagePaidUpfront
    ? subtract(status.amountRemainingFromBorrower, (escrowShortage ?? 0) / 12)
    : status.amountRemainingFromBorrower;

  const desktop = `${paymentAmountFormat(amount)} (due ${paymentDateFormat(paymentDate)})`;
  const tablet = `${paymentAmountFormat(amount)} (due ${paymentDateFormat(paymentDate, true)})`;

  return { desktop, tablet };
};

const formatChargeDueItem = (charge: PortalChargeFragment): PaymentFormAmountDueDisplay => {
  const { amountRemaining } = charge;
  const description = charge.__typename === 'Fee' ? 'fee' : 'advance';
  const chargeDescription = `${paymentAmountFormat(amountRemaining)} (${description})`;

  return { desktop: chargeDescription, tablet: chargeDescription };
};

export const getPaymentFormAmountDueDisplay = (
  paymentsDue: PortalPaymentDueFragment[],
  chargesDue: PortalChargeFragment[],
): PortalDue[] => {
  // Sort the Payments + Fees from oldest to newest
  const orderedPaymentsDue = getUnfulfilledPaymentsDue(paymentsDue);
  const orderedChargesDue = reverse([...chargesDue]);

  // Format the items for display with the charges appearing last
  return [...orderedPaymentsDue, ...orderedChargesDue];
};
