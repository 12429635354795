/*
    The pre-active state displays when the amortizationMonth = 0
*/

import { getCdnUrlForFile } from '@willow/shared-iso';
import { NamedMemo, paymentDateFormat } from '@willow/shared-web';

import { PageHeading } from '../../page-heading/PageHeading';

import './PreActive.scss';

interface Props {
  nextStatementDate: string | undefined;
}

export const PreActive = NamedMemo<Props>('PreActive', ({ nextStatementDate }) => {
  return (
    <>
      <PageHeading
        title="No payment due"
        subtitle={`First statement available ${paymentDateFormat(nextStatementDate)}`}
      />

      <div className="pre-active__container">
        <section>
          <div className="pre-active__header-illustration" aria-hidden="true">
            <div className="pre-active__desktop">
              <img src={getCdnUrlForFile('preActiveIllustration0')} alt="preActiveIllustration0" />
            </div>
            <div className="pre-active__mobile">
              <img src={getCdnUrlForFile('preActiveIllustration0Mobile')} alt="preActiveIllustration0Mobile" />
            </div>
            <div className="pre-active__question-mark">
              <img
                src={getCdnUrlForFile('preActiveIllustrationQuestionMark')}
                alt="preActiveIllustrationQuestionMark"
              />
            </div>
          </div>

          <h2 className="pre-active__title">When is my first mortgage payment due?</h2>
          <ol className="pre-active__list">
            <li className="pre-active__list__item">
              <div className="pre-active__list__item__image" aria-hidden="true">
                <img src={getCdnUrlForFile('preActiveStep1Illustration')} alt="preActiveStep1Illustration" />
              </div>
              <div className="pre-active__list__item__content">
                <div className="pre-active__list__item__content__number pre-active__desktop" aria-hidden="true">
                  <img src={getCdnUrlForFile('preActiveNum1')} alt="preActiveNum1" />
                </div>
                <div className="pre-active__list__item__content__number pre-active__mobile" aria-hidden="true">
                  <img src={getCdnUrlForFile('preActiveNum1Mobile')} alt="preActiveNum1Mobile" />
                </div>
                <div>
                  <h3 className="pre-active__list__item__content__title">Closing Payments</h3>
                  <div className="pre-active__list__item__content__description">
                    You paid applicable closing costs which may have included your down payment, taxes, and even
                    interest covering the month you closed in.
                  </div>
                </div>
              </div>
            </li>

            <li className="pre-active__list__item">
              <div className="pre-active__list__item__image" aria-hidden="true">
                <img src={getCdnUrlForFile('preActiveStep2Illustration')} alt="PreActiveStep2Illustration" />
              </div>
              <div className="pre-active__list__item__content">
                <div className="pre-active__list__item__content__number pre-active__desktop" aria-hidden="true">
                  <img src={getCdnUrlForFile('preActiveNum2')} alt="PreActiveNum2" />
                </div>
                <div className="pre-active__list__item__content__number pre-active__mobile" aria-hidden="true">
                  <img src={getCdnUrlForFile('preActiveNum2Mobile')} alt="PreActiveNum2Mobile" />
                </div>
                <div>
                  <h3 className="pre-active__list__item__content__title">Moving In</h3>
                  <div className="pre-active__list__item__content__description">
                    Now that you’re moved in, enjoy your new home for at least 30 days without having to make a mortgage
                    payment.
                  </div>
                </div>
              </div>
            </li>

            <li className="pre-active__list__item">
              <div className="pre-active__list__item__image" aria-hidden="true">
                <img src={getCdnUrlForFile('preActiveStep3Illustration')} alt="PreActiveStep3Illustration" />
              </div>
              <div className="pre-active__list__item__content">
                <div className="pre-active__list__item__content__number pre-active__desktop" aria-hidden="true">
                  <img src={getCdnUrlForFile('preActiveNum3')} alt="PreActiveNum3" />
                </div>
                <div className="pre-active__list__item__content__number pre-active__mobile" aria-hidden="true">
                  <img src={getCdnUrlForFile('preActiveNum3Mobile')} alt="PreActiveNum3Mobile" />
                </div>
                <div>
                  <h3 className="pre-active__list__item__content__title">Your First Payment</h3>
                  <div className="pre-active__list__item__content__description">
                    Your mortgage payment will be due at the beginning of the first full month after your closing.
                    Expect your bill a couple of weeks before your due date.
                  </div>
                </div>
              </div>
            </li>
          </ol>
        </section>

        <section className="pre-active__timeline">
          <h2 className="pre-active__timeline__title">First Mortgage Payment Timeline Example</h2>
          <div
            className="pre-active__timeline__illustration"
            role="img"
            aria-label="Closing day: May 7th. Bill sent: June 16th. Mortgage due: July 1st. Prepaid interest for this time period was collected at closing."
          >
            <div className="pre-active__desktop" aria-hidden="true">
              <img src={getCdnUrlForFile('preActiveTimeline')} alt="PreActiveTimeline" />
            </div>
            <div className="pre-active__mobile" aria-hidden="true">
              <img src={getCdnUrlForFile('preActiveTimelineMobile')} alt="PreActiveTimelineMobile" />
            </div>
          </div>
        </section>
      </div>
    </>
  );
});
