import { ErrorMessage } from '@hookform/error-message';
import { ReactNode } from 'react';
import { Control, Controller, FieldPathByValue, FieldValues, useController } from 'react-hook-form';

import { Form } from '../../../bootstrap';

type Props<T extends FieldValues> = {
  // Only allow string values to be used with this component
  fieldName: FieldPathByValue<T, string | undefined>;
  control: Control<T>;
  options: { value: string; label: ReactNode }[];
  label?: ReactNode;
  required?: boolean;
  hideErrorMessage?: boolean;
};

export function ControlledRadio<T extends FieldValues>({
  fieldName,
  control,
  options,
  label,
  required,
  hideErrorMessage,
}: Props<T>) {
  const {
    formState: { errors },
  } = useController({ name: fieldName, control });
  return (
    <>
      {label && (
        <Form.Label className="u-fs-2 fw-bold">
          {label}
          {required && <span className="u-color-red1 ps-1 ">*</span>}
        </Form.Label>
      )}
      <div>
        <Controller
          control={control}
          name={fieldName}
          render={({ field }) => (
            <>
              {options.map((option) => (
                <Form.Check key={option.value} id={option.value} type="radio" className="pb-2">
                  <Form.Check.Input
                    type="radio"
                    {...field}
                    value={option.value}
                    checked={field.value === option.value} // explicit set so that parent reset() works
                    className="checked:u-bg-primary"
                  />
                  <Form.Check.Label>{option.label}</Form.Check.Label>
                </Form.Check>
              ))}
            </>
          )}
        />
      </div>
      {!hideErrorMessage && (
        <ErrorMessage
          errors={errors}
          name={fieldName as any} // This *should* work without casting but it doesn't
          render={({ message }) => <div className="u-fs-2 u-color-red1">{message}</div>}
        />
      )}
    </>
  );
}
