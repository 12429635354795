import { PortalSelfQuery } from '@willow/graphql-iso/src/portal';
import { NamedMemo } from '@willow/shared-web';

import './ViewingAs.scss';

export const ViewingAs = NamedMemo<{ portalUser?: PortalSelfQuery['portalSelf'] }>('ViewingAs', ({ portalUser }) => {
  return portalUser ? (
    <p className="viewing-as-container">
      Viewing as {portalUser.firstName} {portalUser.lastName}
    </p>
  ) : (
    <p className="viewing-as-container">No user data retrieved</p>
  );
});
