import { ReactNode } from 'react';
import ReactSelect, { components, OptionProps, Props as SelectProps, ValueContainerProps } from 'react-select';

type OptionType = { label: ReactNode | JSX.Element; value: string | number };
function Option<TOptions extends OptionType, TIsMulti extends boolean>({
  isSelected,
  ...props
}: OptionProps<TOptions, TIsMulti>) {
  return (
    <div>
      <components.Option {...{ isSelected, ...props }}>
        <div className="d-flex gap-2">
          <input type="checkbox" className="form-check-input" checked={isSelected} onChange={() => null} />
          <label
            className="u-color-bark4"
            style={{ textOverflow: 'ellipsis', maxWidth: '85%', overflow: 'hidden', whiteSpace: 'nowrap' }}
          >
            {props.data.label}
          </label>
        </div>
      </components.Option>
    </div>
  );
}

export function ValueContainer<TOptions extends OptionType, TIsMulti extends boolean>({
  children,
  ...props
}: ValueContainerProps<TOptions, TIsMulti>) {
  if (!props.hasValue) return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  // @ts-ignore
  const otherChildren = children ? children[1] : undefined;
  const selectedOptions = props.hasValue ? props.getValue() : [];
  const visibleOptions = selectedOptions.slice(0, 1);
  const remainingCount = selectedOptions.length - visibleOptions.length;

  return (
    <components.ValueContainer {...props}>
      <span style={{ textOverflow: 'ellipsis', maxWidth: '100%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        {visibleOptions.map((op) => op.label)}
        {remainingCount > 0 && <>+{remainingCount}</>}
      </span>
      {otherChildren}
    </components.ValueContainer>
  );
}

export function MultiSelect<TOptions extends OptionType>({ onChange, ...props }: SelectProps<TOptions, true>) {
  return (
    <div className="multiselect-wrapper">
      <ReactSelect
        isMulti
        components={{ Option, ValueContainer }}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        onChange={onChange}
        styles={{
          control: (styles) => ({
            ...styles,
            cursor: 'pointer',
          }),
          indicatorSeparator: () => ({}),
          option: (styles, { isDisabled }) => {
            return {
              ...styles,
              opacity: isDisabled ? 0.4 : 1,
              cursor: isDisabled ? 'none' : 'pointer',
            };
          },
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'var(--color-bark1)',
            primary25: 'var(--color-bark1)',
          },
        })}
        {...props}
      />
    </div>
  );
}
