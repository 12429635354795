/*
    Helper functions to determine state or view of the loan to display
*/
import { DateTime, Interval } from 'luxon';

import { PortalTransferDataFragment } from '@willow/graphql-iso/src/portal';
import { stripNullFromNullOrUndefined } from '@willow/shared-iso';

import { PortalSelectedLoan } from '../App';

export const isTransferredView = (loan: PortalSelectedLoan): Boolean => {
  if (!loan) return false;

  const { paymentDueStatus, loanStatus, transferData, payments, charges } = loan.currentSnapshot;

  const willTransferBeforeFirstPaymentDue = () => {
    // Display "transferred" if loan is scheduled to transfer before the first payment is due
    return !!transferData && new Date(transferData.transferEffectiveDate) <= new Date(loan.configuration.firstDueDate);
  };

  const isTransferringAndPaymentsAreFulfilled = () => {
    // Ensure loan is pending transfer OR transferred, and has transferData
    const isTransferredOrPendingTransfer = Boolean(
      (loanStatus === 'transferred' || loanStatus === 'pendingTransfer') && !!transferData,
    );
    // Ensure there will be no more payments due between now and the transfer date
    // (we do this by checking to see if no more months start before then)
    const inOrAfterLastMonthBeforeTransfer = getInOrAfterLastMonthBeforeTransfer(
      new Date(),
      stripNullFromNullOrUndefined(transferData),
    );
    // Ensure there are no outstanding payments due
    const noPaymentsDue = paymentDueStatus !== 'due';
    // Ensure there are no payments that are currently processing. If so, continue showing the payment screen.
    const noPaymentsProcessing = !payments.some((payment) => payment.status === 'PENDING');
    //Ensure there are no outstanding charges due
    const noFeesDue = !charges.some((charge) => charge.status === 'CHARGED');
    // Display "transferred" if the loan is transferred or nearing transfer and there are no more payments due or processing.

    return (
      isTransferredOrPendingTransfer &&
      inOrAfterLastMonthBeforeTransfer &&
      noPaymentsDue &&
      noPaymentsProcessing &&
      noFeesDue
    );
  };

  return willTransferBeforeFirstPaymentDue() || isTransferringAndPaymentsAreFulfilled();
};

export const getInOrAfterLastMonthBeforeTransfer = (
  todayDate: Date,
  transferData: PortalTransferDataFragment | undefined,
) => {
  if (!transferData || !transferData?.transferEffectiveDate) return false;

  const today = DateTime.fromJSDate(todayDate);
  const lastDayBeforeTransfer = DateTime.fromJSDate(new Date(transferData.transferEffectiveDate)).minus({ days: 1 });
  // NOTE This will break if you update luxon > 2. It happens because `interval.count('months')`
  // returns a different value.
  // with luxon@2
  //   const interval = Interval.fromDateTimes(new Date('4/15/2022'), new Date('5/1/2022'));
  //   interval.count('months') // returns 2
  // with luxon@3
  //   const interval = Interval.fromDateTimes(new Date('4/15/2022'), new Date('5/1/2022'));
  //   interval.count('months') // returns 1
  const interval = Interval.fromDateTimes(today, lastDayBeforeTransfer);
  const monthsUntilTransfer = interval.count('months');
  return !monthsUntilTransfer || monthsUntilTransfer < 2;

  // Keeping this commented out because it comes close to working with luxon@3
  // however `isTransferringAndPaymentsAreFulfilled` (above) uses this function and
  // does run into a snag with the `MakePayment.test.tsx` file
  //
  // Updated logic will return TRUE if today is after the transfer date
  // OR  if there will be no more payments due between today and the transfer date
  // (we do this by checking to see if no more months start before then)
  // return (
  //   today > lastDayBeforeTransfer ||
  //   (today.hasSame(lastDayBeforeTransfer, 'year') && today.hasSame(lastDayBeforeTransfer, 'month'))
  // );
};

// Keeping this commented out for now. The `<PaidCurrentHeader />` is the only place
// outside of this file using `getInOrAfterLastMonthBeforeTransfer` and I think
// the logic could be as simple as this:
//
// export const isNextStatementBeforeTransfer = (
//   nextStatementDate: Date,
//   transferData: PortalTransferDataFragment | undefined | null,
// ) => {
//   if (!transferData || !transferData?.transferEffectiveDate) return true;
//   const lastDayBeforeTransfer = DateTime.fromJSDate(new Date(transferData.transferEffectiveDate)).minus({ days: 1 });
//   return DateTime.fromJSDate(nextStatementDate) < lastDayBeforeTransfer;
// };
